import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"

const Contact = () => {
  return (
    <div className="justify-between my-24 text-center bg-top rounded-md p-7 md:p-10 md:flex md:text-left">
      <div className="">
        <div className="text-xl font-kiwiMaru md:text-2xl">
          お気軽にご見学ください
        </div>
        <div className="mt-3">
          <div>ご見学やご相談・入園申込については随時、受付中です。</div>
          <div>お気軽にご相談ください。</div>
        </div>
      </div>
      <div className="flex items-center justify-center mx-auto mt-5 md:mt-0">
        <FontAwesomeIcon icon={faPhone} size="lg" color="dimgray" />
        <a
          href="tel:048-598-4012"
          className="ml-5 text-2xl font-kiwiMaru md:text-3xls"
        >
          048-598-4012
        </a>
      </div>
    </div>
  )
}

export default Contact
