import React from "react"

import Layout from "../components/top/Layout"
import SEO from "../components/Seo"
import Concept from "../components/top/Concept"
import Projects from "../components/top/Projects"
import Contact from "../components/top/Contact"
import Particles from "../components/shared/Particles"

const IndexPage = () => (
  <>
    <Particles />
    <div className="relative">
      <Layout>
        <SEO />
        <Concept />
        <Projects />
        <Contact />
      </Layout>
    </div>
  </>
)

export default IndexPage
