import { Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { getBgImage, Target } from "../../utils"

type Props = {
  logo: IGatsbyImageData
  title: string
  area: string
  type: string
  path: Target
}

const ProjectCard: React.VFC<Props> = ({ logo, title, area, type, path }) => {
  return (
    <Link
      to={`/${path}/`}
      className={`bg-cover rounded-md p-5 md:p-7 shadow-md text-center ${getBgImage(
        path
      )} hover:transition-shadow duration-150 hover:shadow-none hover:opacity-100 block`}
    >
      <div className="flex items-center justify-center mx-auto">
        <GatsbyImage image={logo} alt="logo" />
        <div className="mx-5 text-3xl font-kiwiMaru md:mr-0">{title}</div>
      </div>
      <div className="mt-3 whitespace-pre-wrap md:mt-6">{type}</div>
      <div className="mt-2 md:mt-3">{area}</div>
    </Link>
  )
}

export default ProjectCard
