import React from "react"
import ParticlesJS from "react-tsparticles"
// import ParticlesParams from "../../config/particlesjs-config.json"
import { loadBubblesPreset } from "tsparticles-preset-bubbles"

const Particles = () => {
  const options = {
    preset: "bubbles",
  }
  const customInit = async main => {
    // this adds the preset to tsParticles, you can safely use the
    loadBubblesPreset(main)
  }
  return (
    <ParticlesJS
      className="absolute w-full h-full"
      options={options}
      init={customInit}
    />
  )
}

export default Particles
