import React from "react"
import HeadingM from "../shared/HeadingM"

const Concept = () => (
  <>
    <HeadingM title="企業理念" target="top" />
    <div className="mt-12 text-center md:mt-16">
      <div className="text-lg md:text-xl md:leading-relaxed">
        <p>
          １. 途切れない支援を目指し、
          <br className="hidden md:block" />
          地域に寄り添い意味のある場所を創造・構築する。
        </p>
        <p className="mt-5">
          ２. 従来の福祉事業の概念に捉われず、
          <br className="hidden md:block" />
          新しい挑戦を追求し従業員の成長と幸福を図る。
        </p>
      </div>
      <div className="mt-10 text-xl leading-relaxed md:text-xl md:leading-relaxed font-kiwiMaru">
        人はこの世に生まれてから日々成長する。
        <br className="hidden md:block" />
        NOONEでは
        <span className="font-bold text-Brown">
          生涯に渡り関わりが途切れない場所
        </span>
        を創造していく事を目指しています。
        <br className="hidden md:block" />
        NOONEとして
        <span className="font-bold text-Brown">関わる全ての【ヒト】</span>
        への役割を果たして行きます。
      </div>
    </div>
  </>
)

export default Concept
